




:root {


  // Theme colors

  --primary-color-orange:#fcba00;
  --primary-color-green:#15939A;

  --secondary-color-blue:#1279ff;
  --secondary-color-gray:#f8f8f3;
  --secondary-color-green:#15939A;

  --theme-color-offwhite:#fcfcfc;
  --theme-color-white:#fff;
  --theme-color-dark:#828282;
  --theme-color-lightgrey: #f8f8f3;
  --theme-color-lightbg: #fafafa;
  --theme-color-lightbg: #fafafa;
  --theme-color-lightbluebg: #E9F6FF;

  --primary-gradient:linear-gradient(95deg, var(--secondary-color-green) 27%, var(--secondary-color-blue) 133%);

  // Typography colors & Typefaces

  --font-color-light:#fff;
  --font-color-green:#15939A;
  --font-color-dark:#081c1b;
  --font-color-dark2:#1C1C1C;
  --font-color-dark3:#565F65;
  --font-color-light-grey: #6a6a6a;
  --font-color-grey: #9394A0;

  --font-color-success:#00d65f;
  --font-color-error:#d0021b;
  --font-color-netural:#575f65;
  --font-color-content-dark: #252525; 
  --font-color-content-light:#828282;

  --font-color-accent-blue-1: #008CF0;


  --font-bold:'NunitoSans Bold',sans-serif;
  --font-semi-bold:'NunitoSans SemiBold',sans-serif;
  --font-medium:'NunitoSans Medium',sans-serif;
  --font-regular:'NunitoSans Regular',sans-serif;
  --font-montserrat: 'Montserrat Medium',sans-serif;

  --border-radius-sm:4px;
  --border-radius-md: 6px;
  --border-radius-lg:8px;


  --color-clp-text1:#000000;
  --color-clp-text2:#1C1C1C;
  --color-clp-text3:#3D3D3D;
  --color-clp-text4:#565F65;
  --color-clp-grey:#9394A0;

}
