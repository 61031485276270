@import "../../base/layout";

.item_toast{
  display: flex;
  position: absolute;
  background: #3E4054;
  z-index: 2;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  padding: 13px 40px 14px 19px;
  margin-right: 10px;
  margin-left: 10px;
  @media (min-width: map-get($grid-breakpoints,lg)) {
      position: fixed;
      top: 100px;
      width: 320px;
  }
  @media (max-width: map-get($grid-breakpoints,md)) {
    right: 0;
}
  @media (max-width: map-get($grid-breakpoints,sm)) {
      margin: auto;
      position:fixed;
      left: 0;
      box-shadow: 2px 0 4px 0 #00000019;
      bottom: 4.5rem;
      margin-left: 10px;
      margin-right: 10px;
  }
  img{
      width: 16px;
      height: 16px; 
  }
  p{
      color: #ffffff;
      font-size: 12px;
      line-height: 14px;
      font-family: var(--font-semi-bold);
      padding-left: 14px;
  }
}

.__openToast{
  display: flex;
  position: absolute;
  background: #DCFEE0;
  border: 1px solid #4DBD58;
  z-index: 2;
  font-size: 14px;
  line-height: 16px;
  color: #4DBD58;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  padding: 13px 40px 14px 19px;
  margin-right: 10px;
  margin-left: 10px;
  transform: translateX(0);
  font-family: var(--font-semi-bold);
  transition:opacity 200ms cubic-bezier(0.4, 0.0, 0.2, 1),transform 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
  column-gap: 8px;
  align-items: center;
  @media (min-width: map-get($grid-breakpoints,lg)) {
      position: fixed;
      top: 100px;
      width: 420px;
      right: 30px;
  }
  @media (max-width: map-get($grid-breakpoints,md)) {
    right: 0;
}
  @media (max-width: map-get($grid-breakpoints,sm)) {
      top: 80px;
      margin: auto;
      position:fixed;
      left: 0;
      box-shadow: 2px 0 4px 0 #00000019;
      margin-left: 10px;
      margin-right: 10px;
  }
  
}
.__closeToast{
  position: absolute;
  opacity: 0;
  z-index: 9999999;
}
.item_toast_email{
  display: flex;
  position: absolute;
  background: #3E4054;
  z-index: 2;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  padding: 13px 32px 14px 19px;
  margin-right: 8px;
  margin-left: 8px;
  @media (min-width: map-get($grid-breakpoints,lg)) {
      position: fixed;
      top: 100px;
      width: 320px;
      right: 40px
  }
  @media (max-width: map-get($grid-breakpoints,md)) {
    right: 0;
}
  @media (max-width: map-get($grid-breakpoints,sm)) {
      margin: auto;
      position:fixed;
      left: 0;
      box-shadow: 2px 0 4px 0 #00000019;
      bottom: 2.5rem;
      margin-left: 10px;
      margin-right: 10px;
  }
  img{
      width: 16px;
      height: 16px; 
  }
  p{
      color: #ffffff;
      font-size: 12px;
      line-height: 14px;
      font-family: var(--font-semi-bold);
      padding-left: 14px;
  }
}

.header {
//   position: relative;
    transition:all 200ms ease-in;
    top:-50px;
    position:fixed;
    width:100%;
    left:0;
    top: 0;
    z-index:999;
    background:#fff;
    transition:top 300ms ease-out;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    height: 56px;
    @media (min-width: map-get($grid-breakpoints,sm)) {
        height:80px;
    }
    ._branding {
        ._logo {
            width:80px;
            height:22px;
            @media (min-width: map-get($grid-breakpoints,lg)) {
                width:130px;
                height: 36px;
            }
            .isDisabled{
              pointer-events: none;
            }
            a {
                display: block;
                width:100%;
                height:100%;
                font-size:0;
                text-indent: -1000%;
                overflow: hidden;
                background: url(../../../public/static/images/onsite-logo.png) no-repeat center;
                background-size: contain;
                &:focus {
                  outline:0;
                  filter:drop-shadow(1px 1px 2px rgba(0,0,0,0.2));
                }
            }
        }
    }
    ._right {
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @media (min-width: map-get($grid-breakpoints,lg)) {
            display: inline-flex;
            align-items: center;
            height: 100%;
            justify-content: flex-end;
        }
    }
    ._left {
        text-align: left;
       display: flex;
       column-gap: 10px;
    }
    ._container , ._row {
        height: 100%;
    }
    @media (min-width: map-get($grid-breakpoints,md)) {
      ._container {
        position: relative;
        z-index: 3;
        height: 100%;

      }
    }
    ._row {
        align-items: center;
    }
    &[data-inverse="true"] {
        background: var(--primary-color-green);
        ._branding ._logo a {
            background: url(../../../public/static/images/onsite-logo-inverse.webp) no-repeat center;
            background-size: contain;
        }
    }
    .help_link {
        color: #fff !important ;
        margin-left:auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        a{
          display: flex;
          align-items: center;
        }
        i{
          margin-left: 6px;
          margin-right: 2px;
        }
    }
    .salespro_link {
      a {
        color: #fff;
        font-weight: bold;
        text-decoration: none;
        display: flex;
        align-items: center;
      }
    }
  .plan_selection {
    @media(min-width: map-get($grid-breakpoints, lg)) {
      margin: auto;
      margin-left: -20px;
      .dropdown_fields {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 40px;
      }
      .plan_selection__dropdown_wrapper {
        border-radius: 8px;
        border: solid 1px #c9c9c9;
        height: 38px;
        display: flex;
        justify-content: flex-start;
        overflow: show;
      }
      li {
        display: flex;
        width: auto;
      }
      .plan_selection__button {
        font-size: 14px;
        font-weight: 500;
        width: 130px;
        height: 48px;
        padding: 17px 25px;
      }
      .plan_selection__cta_fields {
        display: flex;
      }
    }
  }
  .plan_selection__mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--theme-color-white);
    padding: 17px 16px 17px 22px;
    font-size: 14px;
    font-weight: bold;
    z-index: 1;
  }
  .plan_selection__mobile_modal {
    padding-bottom: 16px;
    text-align: left;
    .step_label {
      font-size: 12px;
      font-weight: 500;
    }
    .step_title {
      margin-top: 8px;
      font-family: var(--font-bold)
    }
    .step_subtitle {
      margin-top: 8px;
      font-size: 12px;
      color: #919191;
      max-width: 273px;
      line-height: 1.33;
      padding-bottom: 14px;
      border-bottom: solid 1px rgba(151,151,151,0.2)
    }
    .device_details {
      margin-top: 23px;
    }
    .plan_details {
      margin-top: 37px;;
    }
    .bottom_actions {
      display: flex;
      justify-content: space-between;
      padding: 0;
      justify-content: space-between;
      margin-top: 75px;
      [data-variant='secondary'] {
        height: 40px;
        padding-top:13px;
        padding-bottom: 13px;
      }
      [data-variant='primary'] {
        height: 40px;
        padding-top:13px;
        padding-bottom: 13px;
      }
    }
  }
  .cart_icon {
        position: relative;
        display: inline-block;
        margin-right:0;
        .cart_count {
          position: absolute;
          width:20px;
          height:20px;
          background:var(--primary-color-orange);
          font-size:10px;
          color:#000;
          top:-12px;
          right:-12px;
          border-radius: 50%;
          font-family: var(--font-bold);
          text-align: center;
          line-height: 20px;
        }
        @media (min-width: map-get($grid-breakpoints,lg)) {
            margin-right:0;
            order:2;
        }

    }
    .profile_icon{
      position: relative;
      display: inline-block;
      padding-top: 4px;
      vertical-align: middle;
      margin-left: 20px;
      @media (min-width: map-get($grid-breakpoints,lg)) {
       display: none;
    }
      
    }
  .pulsate{
    position: fixed;
    left: 0px;
    bottom: 10%;
    
  }
    .cart_btn {
        position: relative;
        display: inline-block;
        text-indent: -100%;
        font-size:0;
        @media (min-width: map-get($grid-breakpoints,lg)) {
            &:focus {
              outline:0;
              background-color: rgba(253,185,19,0.1);
              border-radius:4px;
              box-shadow:0 0 0 8px rgba(253,185,19,0.1);
            }
            &[data-icon="true"]:after {
              position: absolute;
              content:"";
              width:0;
              height:0;
              border:7px solid transparent;
              border-top-color:var(--primary-color-green);
              left:50%;
              bottom:0;
              transform: translate(-50%,40px);
              z-index:5;
          }

        }
        &:before {
            display: inline-block;
            font-family: "Icon";
            font-size:22px;
            content:"\e902";
            color:#fff;
            vertical-align: middle;
            font-weight:lighter;
            @media (min-width: map-get($grid-breakpoints,lg)) {
                font-size: 28px;
            }
        }
    }
    .whatsapp_btn {
      position: relative;
      display: inline-block;
      text-indent: -100%;
      font-size:0;
      padding-right: 15px;
      @media (min-width: map-get($grid-breakpoints,lg)) {
        display: none;
      }
      &:before {
          display: inline-block;
          vertical-align: middle;
          font-weight:lighter;
          content: "";
          width: 48px;
          height: 48px;
          background-repeat: no-repeat;
          background-size: cover !important;
          background: url(../../../public/static/images/whatsapp_icon.webp);
          @media (min-width: map-get($grid-breakpoints,lg)) {
              display: none;
          }
      }
    }
    .whatsapp_nopadding{
      padding-right: 0px;
    }
    .nav_trigger {
        position: relative;
        display:inline-flex;
        align-items: center;
        justify-content:center;
        width:20px;
        height:19px;
        margin-left:10px;
        @media (min-width: map-get($grid-breakpoints,lg)) {
            display:none;
        }
        a {
            display:block;
            width:100%;
            height:3px;
            background:var(--font-color-light);
            border-radius:3px;
            &:before , &:after {
                position:absolute;
                left:0;
                content:"";
                width:100%;
                height:3px;
                background:var(--font-color-light);
                border-radius:3px;
                transform-origin: center center;
                transition:all 300ms ease-out;
            }
            &:before {
                top:0;

            }
            &:after {
                bottom:0;

            }

        }
        &.__open {
            a {
                background: transparent;
            }
            a:before , a:after {
                top:50%;
            }
            a:before {
                transform:translateY(-50%) rotate(45deg);
            }
            a:after {
                transform:translateY(-50%) rotate(-45deg);
            }
        }

    }
    &[data-inverse="false"] {
      .profile_btn{
        color: #000;
      }
        .cart_btn{
            &:before {
                color:#000;
            }
            &:after {
              border-top-color:#fff;
            }
        }
        .whatsapp_btn{
          &:before {
            background: url('../../../public/static/images/whatsapp_icon.webp') no-repeat;
          }
        }
        .nav_trigger {
            a {
                background:#000;
            }
            &.__open a {
                background: transparent;
            }
            a:before , a:after {
                background: #000;
            }
        }
    }
    .cobrand {

      position: absolute;
      top:-10px;
      right:15px;
      display: inline-block;
      max-width:68px;
      width:60px;
      height: 84px;

      img {
        display: block;
        width:100%;
        height:auto;
      }
      @media (min-width: map-get($grid-breakpoints,lg)) {
        right:0;
        max-width:120px;
        width: auto;
        height: 148px;
        top:20px;
        transform: translateY(0);
      }
    }
}

.hide {@media (max-width: map-get($grid-breakpoints,sm)) {
  display: none;
}};

.profile_btn{
  vertical-align: middle; 
  width: 24;
  height: 24;
  color: #fff;
}
