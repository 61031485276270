@font-face {
    font-family: 'Montserrat Medium';
    src: url('../../public/static/fonts/Montserrat-Medium.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: 'NunitoSans Regular';
    src: url('../../public/static/fonts/NunitoSans_10pt-Regular.ttf') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'NunitoSans Medium';
    src: url('../../public/static/fonts/NunitoSans_10pt-Medium.ttf') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NunitoSans SemiBold';
    src: url('../../public/static/fonts/NunitoSans_10pt-SemiBold.ttf') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NunitoSans Bold';
    src: url('../../public/static/fonts/NunitoSans_10pt-Bold.ttf') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
